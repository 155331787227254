<template>
  <VDataTable
    :headers="headers"
    :items="tosList"
    :server-items-length="pagination.total"
    :loading="loading"
    @pagination="handlePagination"
  >
    <template #item.enabled="{ item }">
      <VIcon :color="item.enabled ? 'success' : 'warning'">
        fal {{ item.enabled ? 'fa-check-circle' : 'fa-times-circle' }}
      </VIcon>
    </template>

    <template #item.actions="{ item }">
      <VBtn
        class="mr-2"
        fab
        x-small
        icon
        :to="{
          name : Names.R_TALENT_TOS,
          params : {
            companyId : item.companyId
          }
        }"
      >
        <VIcon color="primary">
          fal fa-eye
        </VIcon>
      </VBtn>
      <VBtn
        fab
        x-small
        icon
        :to="{
          name : Names.R_TALENT_TOS_EDIT,
          params : {
            companyId : item.companyId
          }
        }"
      >
        <VIcon color="orange">
          fal fa-edit
        </VIcon>
      </VBtn>
    </template>
  </VDataTable>
</template>

<script>
export default {
  name: 'TosCompanyTable',

  inject: ['Names'],

  data() {
    return {
      loading: false,
      companies: [],
      tosList: [],
      headers: [
        {
          text: 'Компания',
          align: 'left',
          sortable: false,
          value: 'companyName',
        },
        {
          text: 'Видимость',
          align: 'left',
          sortable: false,
          value: 'enabled',
        },
        {
          text: '',
          align: 'right',
          sortable: false,
          value: 'actions',
        },
      ],
      pagination: {
        total: 10,
        offset: 0,
        limit: 10,
      },
    };
  },
  methods: {
    async handlePagination({ itemsLength, itemsPerPage, page }) {
      await this.getCompanies();

      this.loading = true;

      const limit = itemsPerPage === -1 ? itemsLength : itemsPerPage;
      const offset = page > 0 ? (page - 1) * limit : 0;

      try {
        const response = await this.$di.api.Talent.tosConfigIndex({ limit, offset });

        this.tosList = this.matcher(this.companies, response.tosConfigs);

        this.pagination = {
          total: response.total || 0,
          offset: response.offset || 0,
          limit: response.limit || 0,
        };
      } catch (error) {
        this.$di.notify.errorHandler(error);
      } finally {
        this.loading = false;
      }
    },
    async getCompanies() {
      try {
        const response = await this.$di.api.Talent.indexCompany();
        this.companies = response.companies;
      } catch (error) {
        this.$di.notify.errorHandler(error);
      }
    },
    matcher(companies = [], tosList = []) {
      return tosList.map((tos) => {
        const company = companies.find((item) => item.id === tos.companyId);

        return {
          ...tos,
          companyName: company.name,
        };
      });
    },
  },
};
</script>
