<template>
  <TTView>
    <VRow>
      <VCol>
        <VCard>
          <VToolbar flat>
            <VToolbarTitle>Условия использования</VToolbarTitle>

            <VSpacer />

            <VBtn
              depressed
              color="primary"
              :to="{ name : Names.R_TALENT_TOS_CREATE }"
            >
              <VIcon left>
                fal fa-plus
              </VIcon>

              Создать
            </VBtn>
          </VToolbar>
          <VCardText>
            <TosCompanyTable />
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import TosCompanyTable from '../../../components/talent/tos/TosCompanyTable.vue';

export default {
  name: 'TosList',
  components: { TosCompanyTable },
  inject: ['Names'],
};
</script>
