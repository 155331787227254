var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VDataTable',{attrs:{"headers":_vm.headers,"items":_vm.tosList,"server-items-length":_vm.pagination.total,"loading":_vm.loading},on:{"pagination":_vm.handlePagination},scopedSlots:_vm._u([{key:"item.enabled",fn:function(ref){
var item = ref.item;
return [_c('VIcon',{attrs:{"color":item.enabled ? 'success' : 'warning'}},[_vm._v(" fal "+_vm._s(item.enabled ? 'fa-check-circle' : 'fa-times-circle')+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('VBtn',{staticClass:"mr-2",attrs:{"fab":"","x-small":"","icon":"","to":{
        name : _vm.Names.R_TALENT_TOS,
        params : {
          companyId : item.companyId
        }
      }}},[_c('VIcon',{attrs:{"color":"primary"}},[_vm._v(" fal fa-eye ")])],1),_c('VBtn',{attrs:{"fab":"","x-small":"","icon":"","to":{
        name : _vm.Names.R_TALENT_TOS_EDIT,
        params : {
          companyId : item.companyId
        }
      }}},[_c('VIcon',{attrs:{"color":"orange"}},[_vm._v(" fal fa-edit ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }